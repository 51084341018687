html,
body {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

body {
  background-image: url('../assets/bg.png');
  background-size: cover;
  background-position: center;
}

body::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
}

#root {
  position: relative;
  z-index: 1;
}

.navbar-logo {
  color: #fff;
  font-size: 32px;
  text-transform: capitalize;
}

.page-title {
  font-size: 40px;
  font-weight: bold;
  padding-bottom: 24px;
  color: #fff;
  text-align: center;
}

.layout-wrapper {
  width: 100%;
  padding: 0 32px;
}

.layout-wrapper > div {
  max-width: 100%;
  width: 100%;
  margin: 0;
  padding: 32px 0;
  flex: 2;
}

.layout-wrapper > img {
  flex: 1;
}

.card-title {
  color: #fff;
  font-size: 24px;
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid #404857;
}

.farm-card-meta-table-wrapper {
  margin: 16px -24px;
}

.farm-card-meta-table {
  width: 100%;
  text-align: center;
  color: #fff;
}

.farm-card-meta-table th,
.farm-card-meta-table td {
  padding: 8px 2px;
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  width: 50px;
}

.farm-card-meta-table th:last-child,
.farm-card-meta-table td:last-child {
  border: 0;
}
